import React, {useState, useEffect} from "react"
import { Button, Container,CloseButton, Modal, Table, OverlayTrigger, Popover } from "react-bootstrap"

import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
//import {  graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"


import './emotleStyle.css';



let currIndex = 0
let currRow = 0
let buttonOff = false
let showAnswer = false
export default () => {

  

  //console.log(phrase.length)
  let [guess,setGuess] = useState([ [],[],[],[],[] ])
  const [modalShow, setModalShow] = useState(false);
  const [instructShow, setInstructShow] = useState(false);
  const [showHints, setShowHints] = useState(false);

  const [phrase, setPhrase] = useState("");
  const [emojis, setEmojis] = useState("");
  const [hints, setHints] = useState("");
  
  useEffect(() => 
  {
    //console.log(JSON.parse(localStorage.getItem('userStats')) )

    let optionList = 
    [
      {def:"to have or take a turn, change, or deviation from a straight line",word:"curve",hint:"bend,arch"},
      {def:"an elaborate and usually abundant meal",word:"feast",hint:"banquet,dinner"},
      {def:"to disturb or irritate especially by repeated acts",word:"annoy",hint:"irritate"},
      {def:"very large or extensive",word:"huge",hint:"giant,vast"},
      {def:"a simple series of words or numerals",word:"list",hint:"catalog,registry"},
      {def:"to form an opinion from little or no evidence",word:"guess",hint:"assume,suspect"},
      {def:"having or displaying excessive self-esteem",word:"proud",hint:"confident,vain"},
      {def:"a state of tranquility",word:"calm",hint:"peaceful,serene"},
      {def:"a center of activity, attraction, or attention",word:"focus",hint:"nexus,center"},
      {def:"to move in circles around an axis or center",word:"spin",hint:"rotate,turn"},
      {def:"to alter, adapt, or refine",word:"edit",hint:"revise"},

    ]
    let start = "04/05/2022"
    let todaysDate = new Date()
    let startDate = new Date(start)
    let diffTime = todaysDate.getTime() - startDate.getTime()
    let diffDays = Math.trunc(diffTime / (1000 * 3600 * 24) )

    let choice = diffDays%(optionList.length)

    
    let tempPhrase = String(optionList[choice].word)
    let tempEmojis = String(optionList[choice].def)
    let tempHint = String(optionList[choice].hint)

    setPhrase(tempPhrase)
    setEmojis(tempEmojis)
    setHints(tempHint)

    let tempGuess = [ [],[],[],[],[] ]
    for (let i = 0; i < tempPhrase.length; i++) 
    {
      tempGuess[currRow].push({letter:"",state:null})
    }
    tempGuess[currRow][0] = {letter:"_",state:null}

    let tempGuessState = JSON.parse(localStorage.getItem('userGuess')) 
    if (tempGuessState != null)
    {
      let prevChoice = choice - 1
      if(prevChoice < 0)
      { prevChoice = (optionList.length - 1) }

      let statePhrase = tempGuessState.phrase
      if (statePhrase == tempPhrase) //if the phrase in memory and the current phrase are the same
      {
        tempGuess = tempGuessState.guessGrid
        currRow = tempGuessState.row

        let wonGame = true
        for (let i = 0; i < tempGuess[currRow].length; i++) 
        {
          if(String(tempGuess[currRow][i].state).localeCompare(String("#36aff5")) != 0)
          {
            wonGame = false
          }
        }

        if(currRow >= 4 || wonGame == true)
        { 
          buttonOff = true
          setTimeout(() => { setModalShow(true)}, 2000);
          showAnswer = true
        }
      }
      //if the phrase in memory and the previous phrase are the same
      else if (String(statePhrase).localeCompare(String(optionList[prevChoice].phrase)) == 0)
      {
        let guessState = {"guessGrid":tempGuess, "phrase":phrase, "row":currRow}
        localStorage.setItem('userGuess', JSON.stringify(guessState) )

        let userStats = JSON.parse(localStorage.getItem('userStats'))
        if( userStats != null )
        {
          let games = 0
          if(userStats.playedGames != null ) 
          { games = parseInt(userStats.playedGames) }

          let wins = 0 
          if(userStats.gamesWon != null ) 
          { wins = parseInt(userStats.gamesWon) }

          let lost = 0 
          if(userStats.gamesLost != null ) 
          { lost = parseInt(userStats.gamesLost) }

          let streaks = 0 
          if(userStats.streak != null ) 
          { streaks = parseInt(userStats.streak) + 1}

          let tempStats = {"playedGames":games,"gamesWon":wins,"gamesLost":lost,"streak":streaks}
          localStorage.setItem('userStats', JSON.stringify(tempStats) )
        }
      }
      else 
      {
        let guessState = {"guessGrid":tempGuess, "phrase":phrase, "row":currRow}
        localStorage.setItem('userGuess', JSON.stringify(guessState) )

        let userStats = JSON.parse(localStorage.getItem('userStats'))
        if( userStats != null )
        {
          let games = 0
          if(userStats.playedGames != null ) 
          { games = parseInt(userStats.playedGames) }

          let wins = 0 
          if(userStats.gamesWon != null ) 
          { wins = parseInt(userStats.gamesWon) }

          let lost = 0 
          if(userStats.gamesLost != null ) 
          { lost = parseInt(userStats.gamesLost) }

          let streaks = 0 //reset the streak to 0 because the current phrase and previous phrase do not match with one in memory

          let tempStats = {"playedGames":games,"gamesWon":wins,"gamesLost":lost,"streak":streaks}
          localStorage.setItem('userStats', JSON.stringify(tempStats) )
        }
      }
    }

    setGuess(tempGuess)

  }, [])

  function keyInput (evt)
  {
    if(evt)
    { evt.preventDefault(); }

    console.log(evt.target.value)
    let val = evt.target.value
    if(val == 1)
    {
      //console.log("is submit")
      //String(phrase).localeCompare(String(mlsParam2))
      //console.log(phrase[3])
      let tempGuess = [...guess]

      for (let i = 0; i < phrase.length; i++) 
      {
        for (let j = 0; j< tempGuess[currRow].length; j++) 
        {
          if(tempGuess[currRow][j].letter.toUpperCase() == phrase[i].toUpperCase())
          {
            tempGuess[currRow][j].state = "#7f40ff"
          }
        }
        
      }

      for (let i = 0; i < phrase.length; i++) 
      {
        //console.log(tempGuess[currRow][i].letter.toUpperCase(),phrase[i].toUpperCase())
        if(tempGuess[currRow][i].letter.toUpperCase() == phrase[i].toUpperCase())
        {
          tempGuess[currRow][i].state = "#36aff5"
        }
        
      }

      let wonGame = true

      for (let i = 0; i < tempGuess[currRow].length; i++) 
      {
        if(String(tempGuess[currRow][i].state).localeCompare(String("#36aff5")) != 0)
        {
          wonGame = false
        }
      }
      if(wonGame)
      {
        buttonOff = true
        showAnswer = true
        //console.log("Congrats")
        setTimeout(() => { setModalShow(true)}, 1000);
        if (typeof window !== 'undefined') 
        {
          let userStats = JSON.parse(localStorage.getItem('userStats'))
          if( userStats != null )
          {
            let games = 0
            if(userStats.playedGames != null ) 
            { games = parseInt(userStats.playedGames) + 1 }

            let wins = 0 
            if(userStats.gamesWon != null ) 
            { wins = parseInt(userStats.gamesWon) + 1 }

            let lost = 0 
            if(userStats.gamesLost != null ) 
            { lost = parseInt(userStats.gamesLost) }

            let streaks = 0 
            if(userStats.streak != null ) 
            { streaks = parseInt(userStats.streak) }

            let tempStats = {"playedGames":games,"gamesWon":wins,"gamesLost":lost,"streak":streaks}
            localStorage.setItem('userStats', JSON.stringify(tempStats) )
          }
          else
          {
            let tempStats = {"playedGames":1,"gamesWon":1,"gamesLost":0,"streak":1}
            localStorage.setItem('userStats', JSON.stringify(tempStats) )
          }
        }
      }
      else
      {
        if(currRow < 4)
        { 
          currRow++
          for (let i = 0; i < phrase.length; i++) 
          {
            tempGuess[currRow].push({letter:"",state:null})
          }
          tempGuess[currRow][0] = {letter:"_",state:null}
        }
        else
        {
          buttonOff = true
          showAnswer = true
          setTimeout(() => { setModalShow(true)}, 1000);
          if (typeof window !== 'undefined') 
          {
            let userStats = JSON.parse(localStorage.getItem('userStats'))
            if( userStats != null )
            {
              let games = 0
              if(userStats.playedGames != null ) 
              { games = parseInt(userStats.playedGames) + 1 }

              let wins = 0 
              if(userStats.gamesWon != null ) 
              { wins = parseInt(userStats.gamesWon) }

              let lost = 0 
              if(userStats.gamesLost != null ) 
              { lost = parseInt(userStats.gamesLost) + 1 }

              let streaks = 0 
              if(userStats.streak != null ) 
              { streaks = parseInt(userStats.streak) }

              let tempStats = {"playedGames":games,"gamesWon":wins,"gamesLost":lost,"streak":streaks}
              localStorage.setItem('userStats', JSON.stringify(tempStats) )
            }
            else
            {
              let tempStats = {"playedGames":1,"gamesWon":0,"gamesLost":1,"streak":1}
              localStorage.setItem('userStats', JSON.stringify(tempStats) )
            }
          }
        }
        currIndex = 0
      }

      if (typeof window !== 'undefined') 
      {
        let guessState = {"guessGrid":tempGuess, "phrase":phrase, "row":currRow}
        localStorage.setItem('userGuess', JSON.stringify(guessState) )
      }
      setGuess(tempGuess) 
    }
    else if (val == 2)
    {
      if (currIndex < phrase.length)
      {
        //console.log("is space")
        let tempGuess = [...guess]
        tempGuess[currRow][currIndex] = {letter:" ",state:null}
        currIndex++
        if(currIndex < (phrase.length) )
        { tempGuess[currRow][currIndex] = {letter:"_",state:null} }
        setGuess(tempGuess) 
      }
    }
    else if (val == 3)
    {
      if (currIndex > 0)
      {
        //console.log("is backspace")
        let tempGuess = [...guess]

        if(currIndex < (phrase.length) )
        { tempGuess[currRow][currIndex] = {letter:"",state:null} }

        currIndex--
        tempGuess[currRow][currIndex] = {letter:"_",state:null}
        if (currIndex < 0)
        {currIndex = 0}
        setGuess(tempGuess)
      }
    }
    else
    {
      if (currIndex < phrase.length)
      {
        //console.log("is letter")
        let tempGuess = [...guess]
        tempGuess[currRow][currIndex] = {letter:val,state:null}
        currIndex++
        if(currIndex < (phrase.length) )
        { tempGuess[currRow][currIndex] = {letter:"_",state:null} }
        setGuess(tempGuess)
      }
    }

  }
  

  //console.log(guess)

  function InputGrid ()
  {
    return(
    <>
      <div className="inputBox">
        {
          guess.map((row) => 
          {
            return(
            <>
              <div className="row">
                {
                  row.map((box) => 
                  {
                    return(
                      <>
                        <div className="tile" style={{backgroundColor:box.state}}>{box.letter}</div>
                      </>
                    )
                  })
                }
              </div>
            </>
            )
          }) 
        }
      </div>
    </>)
  }

  function Keyboard ()
  {
    return(
    <>
      <Button className="keyLetter" value="Q" onClick={(e) => keyInput(e)} disabled={buttonOff}>Q</Button>
      <Button className="keyLetter" value="W" onClick={(e) => keyInput(e)} disabled={buttonOff}>W</Button>
      <Button className="keyLetter" value="E" onClick={(e) => keyInput(e)} disabled={buttonOff}>E</Button>
      <Button className="keyLetter" value="R" onClick={(e) => keyInput(e)} disabled={buttonOff}>R</Button>
      <Button className="keyLetter" value="T" onClick={(e) => keyInput(e)} disabled={buttonOff}>T</Button>
      <Button className="keyLetter" value="Y" onClick={(e) => keyInput(e)} disabled={buttonOff}>Y</Button>
      <Button className="keyLetter" value="U" onClick={(e) => keyInput(e)} disabled={buttonOff}>U</Button>
      <Button className="keyLetter" value="I" onClick={(e) => keyInput(e)} disabled={buttonOff}>I</Button>
      <Button className="keyLetter" value="O" onClick={(e) => keyInput(e)} disabled={buttonOff}>O</Button>
      <Button className="keyLetter" value="P" onClick={(e) => keyInput(e)} disabled={buttonOff}>P</Button><br/>
      <Button className="keyLetter" value="A" onClick={(e) => keyInput(e)} disabled={buttonOff}>A</Button>
      <Button className="keyLetter" value="S" onClick={(e) => keyInput(e)} disabled={buttonOff}>S</Button>
      <Button className="keyLetter" value="D" onClick={(e) => keyInput(e)} disabled={buttonOff}>D</Button>
      <Button className="keyLetter" value="F" onClick={(e) => keyInput(e)} disabled={buttonOff}>F</Button>
      <Button className="keyLetter" value="G" onClick={(e) => keyInput(e)} disabled={buttonOff}>G</Button>
      <Button className="keyLetter" value="H" onClick={(e) => keyInput(e)} disabled={buttonOff}>H</Button>
      <Button className="keyLetter" value="J" onClick={(e) => keyInput(e)} disabled={buttonOff}>J</Button>
      <Button className="keyLetter" value="K" onClick={(e) => keyInput(e)} disabled={buttonOff}>K</Button>
      <Button className="keyLetter" value="L" onClick={(e) => keyInput(e)} disabled={buttonOff}>L</Button><br/>
      <Button className="keyLetter" value={1} onClick={(e) => keyInput(e)} disabled={buttonOff}>SUBMIT</Button>
      <Button className="keyLetter" value="Z" onClick={(e) => keyInput(e)} disabled={buttonOff}>Z</Button>
      <Button className="keyLetter" value="X" onClick={(e) => keyInput(e)} disabled={buttonOff}>X</Button>
      <Button className="keyLetter" value="C" onClick={(e) => keyInput(e)} disabled={buttonOff}>C</Button>
      <Button className="keyLetter" value="V" onClick={(e) => keyInput(e)} disabled={buttonOff}>V</Button>
      <Button className="keyLetter" value="B" onClick={(e) => keyInput(e)} disabled={buttonOff}>B</Button>
      <Button className="keyLetter" value="N" onClick={(e) => keyInput(e)} disabled={buttonOff}>N</Button>
      <Button className="keyLetter" value="M" onClick={(e) => keyInput(e)} disabled={buttonOff}>M</Button>
      <Button className="keyLetter" value={3} onClick={(e) => keyInput(e)} disabled={buttonOff}>
        BACK
      </Button>
      <br/>
    
    </>)
    
  }

  const sharePopover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Copied to Clipboard!
      </Popover.Body>
    </Popover>
  );

  
  function FinishModal(props) 
  {
    let userStats
    if (typeof window !== 'undefined') 
    {
      userStats = JSON.parse(localStorage.getItem('userStats'))
    }
    else
    {
      userStats = null
    }
    let shareOutput = ""
    if (showAnswer)
    {
      let results = []
      results.push("#Definle ")
      results.push(String((currRow+1)).concat("/5 \n"))
      let tempGuess = [...guess]
      for (let i = 0; i < 5; i++) 
      {
        for(let j=0; j < phrase.length; j++)
        {
          if(tempGuess[i][j] != null)
          {
            if(String(tempGuess[i][j].state).localeCompare(String("#36aff5")) == 0)
            {
              results.push('🟦')
            }
            else if(String(tempGuess[i][j].state).localeCompare(String("#7f40ff")) == 0)
            {
              results.push('🟪')
            }
            else
            {
              results.push('⬛')
            }
          }
          else
          {
            results.push('⬛')
          }
        }
        results.push('\n')
      }
      results.push("https://Definle.com/")
      //console.log("result is", String(results.join("")))
      shareOutput = String(results.join(""))

    }

    function SocialButtons ()
    {
      return(
        <>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
          />
          <div class="share-btn-container">
            
            <span style={{fontSize:"30px"}}>Share:&nbsp;</span>
            {/*
            <a  href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareOutput)}&via=appraisir`} title="Tweet Score" class="twitter-btn" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>
            
            <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(shareOutput)}`} title="Send on WhatsApp" class="whatsapp-btn" target="_blank">
              <i class="fab fa-whatsapp"></i>
            </a>
            */}

            <OverlayTrigger trigger="click" placement="bottom" overlay={sharePopover}>
              <a  onClick={() => {navigator.clipboard.writeText(shareOutput)}} title="Copy to Clipboard"  className="alignText">
                <i class="fa-solid fa-clipboard-list"></i>
              </a > 
            </OverlayTrigger>
      
          {/*
            <a href={`https://www.facebook.com/sharer.php?u=https://appraisir.com/Daily`} class="facebook-btn" target="_blank">
              <i class="fab fa-facebook"></i>
            </a>

            <a href={`https://pinterest.com/pin/create/bookmarklet/?media=${houseData.photos[0].href}&url=https://appraisir.com/Daily&description=${encodeURIComponent(shareOutput)}`} class="pinterest-btn" target="_blank">
              <i class="fab fa-pinterest"></i>
            </a>

            <a href={`https://www.linkedin.com/shareArticle?url=https://appraisir.com/Daily&title=${encodeURIComponent(shareOutput)}`} class="linkedin-btn" target="_blank">
              <i class="fab fa-linkedin"></i>
            </a>
          */
          }
          </div>
        
        </>
      )
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className = "my-modal"
      >
        <Modal.Header style = {{backgroundColor: "#222",color: "white"}}>
          <Modal.Title  id="contained-modal-title-vcenter">
            Thank you for playing!
          </Modal.Title>
          <CloseButton onClick={props.onHide} variant ='white' />
        </Modal.Header>
        <Modal.Body style = {{backgroundColor: "#222",color: "white"}}>
          { showAnswer &&
            <p style={{textAlign:"center"}}>
              <h3>{emojis} <br/> is <br/> "{phrase}"</h3>
            </p>
          }
          <p>
            <Table variant="dark" striped >
              <tr className="alignText">
                <th className="alignText">Games Played</th>
                <th className="alignText">Win Rate</th>
                {/*<th className="alignText">Streak</th>*/}
              </tr>
              <tr className="alignText">
                <td className="alignText">{userStats ? userStats.playedGames : 0}</td>
                <td className="alignText">{userStats ? Math.trunc(100*(userStats.gamesWon)/(userStats.gamesWon+userStats.gamesLost)) : 0}%</td>
                {/*<td className="alignText">{userStats ? userStats.streak : 0}</td>*/}
              </tr>
            </Table>

          </p>
          <br/>
          Next round will start at 12AM! <br/> 
        
        </Modal.Body>
        <Modal.Footer style = {{backgroundColor: "#222",color: "white"}}>
          {
            /*showAnswer &&
            <OverlayTrigger trigger="click" placement="bottom" overlay={sharePopover}>
              <Button onClick={() => {navigator.clipboard.writeText(shareOutput)}} style = {{backgroundColor: "#36aff5",color: "white"}} title="Share Results"  className="alignText">
                Share Results
              </Button>
            </OverlayTrigger>
            */
          }
          <SocialButtons/>
          {/*<Button onClick={props.onHide} style = {{backgroundColor: "#ff30dc",color: "white"}}>Close</Button>*/}
        </Modal.Footer>
      </Modal>
    );
  }

  function InstructionModal(props) 
  {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className = "my-modal"
      >
        <Modal.Header style = {{backgroundColor: "#222",color: "white"}}>
          <Modal.Title  id="contained-modal-title-vcenter">
            How To Play!
          </Modal.Title>
          <CloseButton onClick={props.onHide} variant ='white' />
        </Modal.Header>
        <Modal.Body style = {{backgroundColor: "#222",color: "white", textAlign:'left'}}>
          <p >
            5 turns to guess the word from the given definition. 
          </p>
          When you click the SUBMIT button:
          <p>
            <div className="instructTile" style={{backgroundColor:'#7f40ff', display:'inline'}}>A</div> 
            &nbsp;Purple tile = correct letter in the wrong location. 
          </p>
          <p>
            <div className="instructTile" style={{backgroundColor:'#36aff5', display:'inline'}}>A</div> 
            &nbsp;Blue tile = correct letter in the correct location. 
          </p>
          <p>
            <div className="instructTile" style={{backgroundColor:'transparent', display:'inline'}}>A</div> 
            &nbsp;Black tile = does not exist in the word.
          </p>
          <br/>
        
        </Modal.Body>
        <Modal.Footer style = {{backgroundColor: "#222",color: "white"}}>
          {/*<Button onClick={props.onHide} style = {{backgroundColor: "#ff30dc",color: "white"}}>Close</Button>*/}
        </Modal.Footer>
      </Modal>
    );
  }

  function HintArea()
  {
    //console.log("show hints is",showHints)
    if(showHints == false) 
    {  
      return (
      <>
        <Button onClick={() => setShowHints(true)} style = {{backgroundColor: "#36aff5",color: "white"}} title="Give Hint"  className="alignText">
          Hint?
        </Button>
      </>
      )
    }
    else
    {
      return (
      <>
        <h4 style={{marginBottom:"3px"}}>Hint: {hints}</h4>
      </>
      )
      
    }
  }

  return (
    <Layout >
      <Seo title="Home" />
      <div className="text-color alignText" style={{maxWidth:"99.9%", margin: "auto"}}>          
        <h2> 
          <a onClick={() => setModalShow(true)} style={{cursor: 'pointer'}}>
            <svg xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 0 24 24" width="35">
              <path fill="#ff30dc" d="M16,11V3H8v6H2v12h20V11H16z M10,5h4v14h-4V5z M4,11h4v8H4V11z M20,19h-4v-6h4V19z"></path>
            </svg>
          </a>

          &nbsp;<u>Definle </u>&nbsp;

          <a onClick={() => setInstructShow(true)} style={{cursor: 'pointer'}}>
            <svg xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 0 24 24" width="35">
              <path fill="#ff30dc" d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
            </svg>
          </a> 
        </h2>
        <div className="emojiPhrase">
          <h3 style={{marginBottom:"3px"}}>Guess the word from this definition:<br/><br/>{emojis}</h3>
          <HintArea/>
        </div>
        <br/>
        <FinishModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <InstructionModal
          show={instructShow}
          onHide={() => setInstructShow(false)}
        />

        <InputGrid/>

        <br/>
        <Keyboard/>

      </div>
    </Layout>
  )

}
